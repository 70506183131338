//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	data(){
		return {
			input: '',
			input2: '',
			input3: '',
			textarea:''
		}
	},
	methods:{
		
	}
}
